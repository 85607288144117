.abt__excel_main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.abt__excel_content_contain {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3rem;
  align-items: center;
  width: 80%;
  padding: 3rem;
  font-family: var(--secondary-font) !important;
}

.abt__excel_content_contain > img {
  width: 25rem;
}
.abt__excel_content_contain > p {
  font-size: 1.2rem;
  font-family: var(--seconday-font);
  font-weight: 400;
  word-spacing: 3px;
}


@media only screen and (max-width: 1200px) and (max-height: 700px) { 

}

@media only screen and (max-width: 1300px) {

}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 1100px) {

}

@media screen and (max-width: 992px) {

}

@media screen and (max-width: 800px) {

}
@media screen and (max-width: 832px) {
    .abt__excel_content_contain{
        flex-direction: column;
    }
}

@media screen and (max-width: 600px) {

}

@media screen and (max-width: 400px) {

}
@media screen and (max-width: 430px) {
    .abt__excel_content_contain>img{
        width: 20rem;
    }
}


@media screen and (max-width: 366px){
    .abt__excel_content_contain>p{
        font-size: 0.9rem;
    }
}
@media only screen and (device-width: 768px) {

}