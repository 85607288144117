.sponsors {
    padding: 4rem 0;
    min-height: 60vh;
    background: #efced7;
}

  
.sponsors_container {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 100%;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}
  
.sponsors_section {
    display: flex;
    gap: 4rem;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
}
  

.sponsor_details {
    display: flex;
    width: 200px;
    height: 200px;
    border-radius: 10px;
    pointer-events: none;
    cursor: pointer;
    transition: ease-in 0.2s;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-end;
}

.sponsor_details img {
    width: 150px;
    cursor: pointer;
}

/* .colleges_img img:hover {
    transform: scale(1.1);
} */

.sponsor_details h2 {
    font-family: var(--primary-font);
    color: var(--primary-color);
    margin-top: 1.15rem;
    font-size: 1.15rem;
    text-align: center;
    font-weight: 600;
    white-space: nowrap;
}


@media only screen and (max-width: 1200px) and (max-height: 700px) { 

}

@media only screen and (max-width: 1300px) {
    .sponsors_container {
        width: 90%;
    }
}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 1100px) {

}

@media screen and (max-width: 992px) {
    .sponsors_container {
        width: 100%;
    }
    .sponsors {
        min-height: 50vh;    
    }
}

@media screen and (max-width: 800px) {
    .sponsors_section {
        gap: 3rem;
    }
    .sponsor_details {
        width: 150px;
        height: 150px;
    }
    .sponsor_details img {
        width: 120px;
        cursor: pointer;
    }
    .sponsor_details h2 {
        margin-top: 0.5rem;
        font-size: 1.05rem;
    }
}

@media screen and (max-width: 600px) {
    .sponsors_section {
        gap: 2rem;
    }
}

@media screen and (max-width: 400px) {

}