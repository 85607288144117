.section__title {
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 800;
    line-height: 95%;
    letter-spacing: 0.04rem;
    text-transform: uppercase;
    color: #f74670;
    text-align: center;
    font-size: 30px; 
    padding: 5px;
    letter-spacing:none;
    position: relative;
}
img{
    pointer-events: none;
}

.section__title::before {
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 50%;
    height: 8px;
    content: "";
    left: 50%;
    margin-left: -25%;
    background: linear-gradient(90deg, rgba(225, 12, 93, 0.7) 0%, rgba(249, 104, 28, 0.7) 100%);
  }

.primary_color {
    color: #f74670 !important;
}

.blue {
    color: var(--secondary-color) !important;
}
  

@media only screen and (max-width: 1200px) and (max-height: 700px) { 

}

@media only screen and (max-width: 1300px) {

}

@media only screen and (max-width: 1200px) {

}

@media only screen and (max-width: 1100px) {

}

@media screen and (max-width: 992px) {

}

@media screen and (max-width: 800px) {
    .section__title {
        font-size: 25px; 
    }
}

@media screen and (max-width: 600px) {

}

@media screen and (max-width: 400px) {

}