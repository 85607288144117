.about {
    min-height: 100vh;
    background:  #930035;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.about__container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 90%;
    margin: auto;
}

.about__left {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0.5;
}

.about_map_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.map_event {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 245px;
    background: linear-gradient(90deg, #e10c5d, #f9681c);
    transform: translate(-75px, 0%) rotate(0deg);
}


.map_event p {
    transform: translate(0, 170px) rotate(-90deg);
    white-space: nowrap;
    font-family: var(--secondary-font);
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #ECF5F8;
}

.about_map_div img {
    width: 440px;
    pointer-events: none;
}

.about_map_div h1 {
    font-family: var(--secondary-font);
    font-size: 2rem;
    line-height: 106%;
    font-weight: 500;
    color: #ECF5F8;
    margin-top: 1rem;
}

.bold9 {
    font-weight: 900;
}

.about__right {
    flex: 0.5;
}

.ar__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
}

.ar__content h1 {
    font-family: var(--secondary-font);
    padding: 0;
    font-size: 3rem;
    line-height: 106%;
    font-weight: 900;
    color: #ECF5F8;

}

.ar__content p {
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #ECF5F8;
}

.ar_footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.ar__item {
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 1rem; */
    background: rgba(255, 255, 255, 0.16);
    width: 180px;
    height: 80px;  
    color: #ECF5F8; 
    padding: 0.5rem;
}

.ar__item_icon {
    flex-shrink: 0;
    flex: 0.3;
    font-size: 1.35rem;
}

.ar__item p {
    flex: 0.7;
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 5600;
    font-size: 1rem;
    line-height: 25px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

@media only screen and (max-width: 1200px) and (max-height: 700px) { 

}

@media only screen and (max-width: 1300px) {
    .about_map_div img {
        width: 400px;
    }
}

@media only screen and (max-width: 1200px) {
    .about__container {
        width: 96%;
    }
    .about_map_div img {
        width: 360px;
    }
    .ar__content h1 {
        font-size: 2.5rem;
    }
}

@media only screen and (max-width: 1100px) {
    .about_map_div img {
        width: 330px;
    }
}

@media screen and (max-width: 992px) {
    .about {
        min-height: 100vh;
        padding-top: 3rem;
    }
    .about__container {
        flex-direction: column;
        align-items: center;
        width: 96%;
        gap: 2rem;
    }
    .map_event {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        width: 245px;
        height: 50px;
        background: linear-gradient(90deg, #e10c5d, #f9681c);
        transform: translate(40px, -50%) rotate(0);
    }
    .map_event p {
        transform: translate(0, 0) rotate(0);
        white-space: nowrap;
        font-family: var(--secondary-font);
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #ECF5F8;
    }

    .about_map_div img {
        width: 400px;
        margin-top: 3rem;
    }
    .about__right {
        flex: 1;

    }
    .ar__content h1 {
        font-size: 2rem;
        padding: 0 2rem;
    }
    .about_map_div h1 {
        font-size: 1.5rem;
    }
    .ar__content p {
        padding: 0 2rem;
    }
    .ar_footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        background: rgba(255, 255, 255, 0.16);
        padding: 0 2rem;
    }
    .ar__item {
        width: 100%;
        height: 100px;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        background: none;
    }
    .ar__item_icon {
        flex-shrink: 0;
        flex: 0.4;
        font-size: 1.35rem;
    }
    .ar__item p {
        font-size: 1rem;
        line-height: 20px;
        padding: 0;
    }
    
}

@media screen and (max-width: 800px) {
    .about__container {
        flex-direction: column;
        align-items: center;
        width: 100%;
        gap: 2rem;
    }
    .about_map_div img {
        width: 320px;
    }
    .about__right {
        flex: 1;
    }
    .ar__content h1 {
        font-size: 1.5rem;
    }
    .ar__content p {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 600px) {

}

@media screen and (max-width: 400px) {

}


@media only screen and (device-width: 768px) {

}