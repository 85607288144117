.home {
  height: 100vh;
  padding-top: 70px;
  /* background-image: url("../../assets/png/grid.png"); */
  background-color: #ffffff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
}

.landing_curve {
  position: absolute;
  bottom: 0%;
  width: 100%;
  pointer-events: none;
  z-index: 1;
}
.landing_curve1 {
  position: absolute;
  top: 0%;
  right: 0%;
  width: fit-content;
  pointer-events: none;
  background-position: top right;
  background-repeat: no-repeat;
  z-index: 0;
}

.home__container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  position: relative;
  height: 100%;
}

.countdown {
  position: absolute;
  bottom: 50px;
  right: 0;
  z-index: 40;
  display: flex;
  align-items: center;
  height: 100px;
  flex-direction: column;
}

.c_starts {
  height: 100%;
  padding: 19px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ecf5f8;
  background: #f74670;
  width: 100%;
}

.timer {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 19px 44px;
  gap: 1.5rem;
  background: #ffe2e9;
  backdrop-filter: blur(10px);
  font-family: var(--secondary-font);
}

.timer_div {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-style: normal;
  padding: 10px;
  color: #d94242;
  font-family: var(--secondary-font);
}

.timer_div h1 {
  font-size: 2.5rem;
  letter-spacing: 0.04em;
  font-family: var(--secondary-font);
  font-weight: 800;
  line-height: 100%;
}
.timer_div p {
  font-size: 14px;
  line-height: 13px;
  letter-spacing: 0.04em;
  text-transform: lowercase;
  font-family: var(--secondary-font);
}

.home__left,
.home__right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home__left {
  flex: 0.5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 4rem;
  gap: 0.75rem;
  z-index: 3;
}

.home__left h1 {
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  letter-spacing: 0.08em;
  line-height: 120%;
  text-transform: uppercase;
  background: linear-gradient(85.52deg, #ef224b -3.49%, #ff3355 100.68%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.home__left h1 span {
  font-weight: 900;
  font-size: 100px;
}

.home__left p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #930035;
  width: 80%;
  background: #ffffff;
}

.home_reg_btn {
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  position: relative;
  outline: none;
  background: linear-gradient(85.52deg, #ef224b -3.49%, #ff3355 100.68%);
  border: 2px solid rgba(208, 28, 28, 0.638);
  z-index: 2;
  border-radius: 20px;
  width: 220px;
  height: 55px;
  font-family: var(--secondary-font);
  font-style: normal;
  font-weight: 600;
  font-size: 1.1rem;
  letter-spacing: 0.05em;
  color: #ecf5f8;
  cursor: pointer;
  text-transform: uppercase;
  margin-top: 0.5rem;
  text-decoration: none;
}
.home_reg_btn:hover {
  transform: scale(1.1);
  background: #f74670;
}
.home__right {
  flex: 0.5;
  display: flex;
  align-items: flex-center;
  justify-content: flex-center;
  height: 100%;
}

.landing_avatar {
  pointer-events: none;
  z-index: 2;
  width: auto;
  height: 100%;
  scale: 0.9;
}

@media only screen and (max-width: 1200px) and (max-height: 700px) {
}

@media only screen and (max-width: 1300px) {
  .home__left {
    padding: 3rem;
    gap: 0.75rem;
  }
  .landing_curve {
    bottom: 0%;
  }
}

@media only screen and (max-width: 1200px) {
  .home__left {
    padding: 2.5rem;
    gap: 0.75rem;
  }
  .landing_curve {
    bottom: 0%;
  }
  .home__left h1 {
    font-size: 50px;
  }

  .home__left h1 span {
    font-size: 80px;
  }
}

@media only screen and (max-width: 1100px) {
  .home__left {
    padding: 0 2rem;
    margin-top: 1.25rem;
    gap: 0.75rem;
  }
  .landing_curve {
    bottom: 0%;
  }
}

@media screen and (max-width: 992px) {
  .home {
    height: 100vh;
    padding-top: 70px;
    /* background-image: url("../../assets/png/grid.png"); */
    background-color: #ffffff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
  }

  .landing_curve {
    position: absolute;
    bottom: 0%;

    width: 100%;
    pointer-events: none;
    z-index: 1;
  }
  .landing_curve1 {
    position: absolute;
    top: 0%;
    right: 0%;
    width: fit-content;
    pointer-events: none;
    background-position: top right;
    background-repeat: no-repeat;
    z-index: 0;
  }

  .home__container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    position: relative;
    height: 100%;
  }

  .countdown {
    width: 100;
    bottom: 50px;
    right: 0;
    display: flex;
    align-items: center;
    height: 100px;
    flex-direction: column;
  }

  .c_starts {
    height: 100%;
    padding: 19px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #ecf5f8;
    background: #f74670;
    width: 100%;
  }

  .timer {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 19px 44px;
    gap: 1.5rem;
    background: #ffe2e9;
    backdrop-filter: blur(10px);
    font-family: var(--secondary-font);
  }

  .timer_div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-style: normal;
    padding: 10px;
    /* color: #f74670; */
    gap: 0.15rem;
    font-family: var(--secondary-font);
    width: 100%;
  }

  .timer_div h1 {
    font-size: 2.5rem;
    letter-spacing: 0.04em;
    font-family: var(--secondary-font);
    font-weight: 800;
    line-height: 100%;
  }
  .timer_div p {
    font-size: 14px;
    line-height: 13px;
    letter-spacing: 0.04em;
    text-transform: lowercase;
    font-family: var(--secondary-font);
  }

  .home__left,
  .home__right {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home__left {
    flex: 0.5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 4rem;
    gap: 0.75rem;
    z-index: 3;
  }

  .home__left h1 {
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    letter-spacing: 0.08em;
    line-height: 120%;
    text-transform: uppercase;
    padding: 0.5rem;
    background: #ffff;
    text-shadow: #af0a3f -6px 0px 0px;
  }

  .home__left > h1 > span {
    font-weight: 900;
    font-size: 100px;
    background: linear-gradient(
      180.52deg,
      #d7cbcd -20.49%,
      #ef6480 -20.49%,
      #fda0e9 120.68%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .home__left span {
    background: linear-gradient(
      180.52deg,
      #d7cbcd -20.49%,
      #ef6480 -20.49%,
      #fda0e9 120.68%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .home__left p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    display: flex;
    align-items: center;
    color: #930035;
    width: 50%;
    background: #ffffff;
  }

  .home_reg_btn {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    position: relative;
    outline: none;
    background: linear-gradient(85.52deg, #ef224b -20.49%, #ef6622 120.68%);
    border: 2px solid rgba(208, 28, 28, 0.638);
    z-index: 2;
    width: 220px;
    height: 55px;
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;
    letter-spacing: 0.05em;
    color: #ecf5f8;
    cursor: pointer;
    text-transform: uppercase;
    margin-top: 0.5rem;
    text-decoration: none;
  }

  .home__right {
    position: absolute;
    bottom: 0;
  }

  .landing_avatar {
    pointer-events: none;
    z-index: 2;
    width: auto;
    height: 100%;
    scale: 0.9;
  }
}
@media only screen and (max-width: 800px) {
  .home {
    height: 100vh;
    padding-top: 70px;
    background-color: #ffffff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
  }

  .landing_curve {
    position: absolute;
    bottom: 0%;
    display: none;
    width: 100%;
    pointer-events: none;
    z-index: 1;
  }

  .home__left h1 {
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    letter-spacing: 0.08em;
    line-height: 120%;
    text-transform: uppercase;
    text-shadow: #af0a3f -6px -0px 0px;
  }

  .home__left h1 span {
    font-weight: 900;
    font-size: 100px;
  }

  .home__left p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    display: flex;
    align-items: center;
    color: #930035;
    width: 80%;
    background: #ffffff;
  }

  .landing_avatar {
    pointer-events: none;
    z-index: 2;
    width: auto;
    height: 100%;
    scale: 0.9;
  }
}

@media screen and (max-width: 600px) {
  .home__left p {
    font-size: 16px;
  }
  .home__left h1 {
    font-size: 70px;

    line-height: 140%;
  }
  .home__left h1 span {
    font-size: 70px;
  }
  .countdown {
    right: 0;
    display: flex;
    align-items: center;
    height: 100px;
    flex-direction: column;
    width: 100vw;
  }
  .timer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 19px 44px;
    gap: 1.5rem;
    background: #ffe2e9;
    backdrop-filter: blur(10px);
    font-family: var(--secondary-font);
    width: 100vw;
  }
  .timer_div {
    transform: translateY(10%);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-style: normal;
    justify-content: center;
    padding: 10px;
    /* color: #f74670; */
    gap: 0.15rem;
    font-family: var(--secondary-font);
    width: 100vw;
  }
}

@media screen and (max-width: 400px) {
  .home__left h1 {
    font-family: var(--secondary-font);
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    letter-spacing: 0.08em;
    line-height: 120%;
    text-transform: uppercase;
    background: #ffff;
    text-shadow: #af0a3f -6px 0px 0px;
  }
  .home__left > h1 > span {
    font-weight: 900;
    font-size: 60px;
    background: linear-gradient(
      180.52deg,
      #d7cbcd -20.49%,
      #d60e36 -20.49%,
      #fda0e9 120.68%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .home__right {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
