.footer__main {
  /* width: 97%; */
  background-color: #72032c;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  color: #ffff;
  font-weight: 300;
  font-family: var(--primary-font);
  font-size: 1rem;
}
.xtwitterlogo{
  width: 25px;
}
.heart_icon {
  width: 1rem;
  height: 1rem;
  margin-bottom: -0.15rem;
  pointer-events: none;
}

.mec___logo_footer {
  width: 9rem;
}
.excel__logo_footer {
  width: 9rem;
}
.footer_content {
  color: #ffff;
  display: flex;
  flex-direction: column;
}
.footer_social_logos {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.footer_social_logos>a{
  width: 2rem;
  height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  color: #ffff;
  font-size: 1.2rem;
}
.footer_social_logos>a:hover{
  
  background-color: var(--secondaryColor);
}
@media screen and (max-width: 680px) {
  .footer__main {
    flex-direction: column;
    gap: 2rem;
  }
  .footer_content {
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
}