.gallery__container{
    width: 100%;
    display: flex;
    gap: 3rem;
    padding: 3rem 0rem 3rem 0rem; 
    flex-direction: column;
    align-items: center;
    min-height: 90vh;
}

.gallery__content_container{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
}
.gallery__content_container>img{
    width: 220px;
    height: 200px;
    border-radius: 20px;
}