.excel {
    height: 50vh;
}

.excel_container {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
}

.excel_left {
    height: 100%;
    padding: 4rem;
    display: flex;
    flex: 0.5;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    background-color: #ff6289;
    gap: 1.5rem;
}

.excel_left_title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
}

.excel_title {
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 95%;
    letter-spacing: 0.04rem;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
    padding: 5px;
    letter-spacing:none;
    position: relative;
}

.excel_title::before {
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 50%;
    height: 8px;
    content: "";
    left: 50%;
    margin-left: -45%;
    background-color: #1CD0B0;
}




.excel_desc {
    font-size: 1rem;
    font-family: var(--primary-font);
    color: #fff;
    font-weight: 400;
    text-align: left;
    width: 80%;
}
.excel_right {
    height: 100%;
    padding: 4rem;
    display: flex;
    flex-direction: column;
    flex: 0.5;
    align-items: flex-end;
    justify-content: center;
    background-color: #f74670;
    gap: 1.5rem;
}

.excel_right_title {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 1rem;
}

.excel_right_title .excel_title::before {
    margin-left: -5%;
}

.excel_desc1 {
    font-size: 1rem;
    font-family: var(--primary-font);
    color: #fff;
    font-weight: 400;
    text-align: right;
    width: 80%;
}

.img_container {
    position: absolute;
}

.excel_img {
    width: 250px;
}

.img_container1 {
    display: none;
}

.excel_img_mobile {
    display: none;
}

@media screen and (max-width: 1200px){
     .excel_img {
        width: 220px;
     }
}

@media screen and (max-width: 1100px){
    .excel_img {
         width: 200px;
    }
    .excel_right, .excel_left {
        padding: 3rem;
    }
}


@media screen and (max-width: 992px) {

    .img_container{
        display: none;
    }

    .excel_img {
        display: none;
    }

    .excel {
        height: 100vh;
    }
    .excel_container {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .excel_left {
        padding: 2rem;
        justify-content: flex-start;
    }

    .excel_right {
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
    }

    .excel_right_title .excel_title::before {
        margin-left: -45%;
    }

    .excel_desc1 {
        text-align: left;
    }

    .img_container1{
        display: block;
        position: absolute;
        right: 10%;
    }

    .excel_img_mobile {
        display: block;
        height: 220px;
    }

}

@media screen and (max-width: 800px) {
    .excel_img_mobile {
        height: 180px;
        pointer-events: none;
    }
    .excel_right, .excel_left {
        height: 400px;
    }
    .img_container1 {
        display: block;
        position: absolute;
        right: 5%;
    }
    .excel_img_mobile {
        height: 200px;
    }
}

@media screen and (max-width: 600px) {
    .excel_desc {
        font-size: 0.8rem;
    }

    .excel_desc1 {
        font-size: 0.8rem;
    }

    .excel_img_mobile {
        height: 180px;
    }

}

@media screen and (max-width: 400px) {

}